exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-agile-events-js": () => import("./../../../src/pages/about-us/agile-events.js" /* webpackChunkName: "component---src-pages-about-us-agile-events-js" */),
  "component---src-pages-about-us-career-js": () => import("./../../../src/pages/about-us/career.js" /* webpackChunkName: "component---src-pages-about-us-career-js" */),
  "component---src-pages-about-us-life-at-agileinfoways-js": () => import("./../../../src/pages/about-us/life-at-agileinfoways.js" /* webpackChunkName: "component---src-pages-about-us-life-at-agileinfoways-js" */),
  "component---src-pages-about-us-our-story-js": () => import("./../../../src/pages/about-us/our-story.js" /* webpackChunkName: "component---src-pages-about-us-our-story-js" */),
  "component---src-pages-about-us-testimonial-js": () => import("./../../../src/pages/about-us/testimonial.js" /* webpackChunkName: "component---src-pages-about-us-testimonial-js" */),
  "component---src-pages-agile-infoways-office-infrastructure-js": () => import("./../../../src/pages/agile-infoways-office-infrastructure.js" /* webpackChunkName: "component---src-pages-agile-infoways-office-infrastructure-js" */),
  "component---src-pages-ai-development-services-js": () => import("./../../../src/pages/ai-development-services.js" /* webpackChunkName: "component---src-pages-ai-development-services-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-clutch-landing-js": () => import("./../../../src/pages/clutch-landing.js" /* webpackChunkName: "component---src-pages-clutch-landing-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-engagement-model-js": () => import("./../../../src/pages/engagement-model.js" /* webpackChunkName: "component---src-pages-engagement-model-js" */),
  "component---src-pages-errorpage-js": () => import("./../../../src/pages/errorpage.js" /* webpackChunkName: "component---src-pages-errorpage-js" */),
  "component---src-pages-flutter-clutch-landing-js": () => import("./../../../src/pages/flutter-clutch-landing.js" /* webpackChunkName: "component---src-pages-flutter-clutch-landing-js" */),
  "component---src-pages-health-care-js": () => import("./../../../src/pages/healthCare.js" /* webpackChunkName: "component---src-pages-health-care-js" */),
  "component---src-pages-himss-2024-js": () => import("./../../../src/pages/himss-2024.js" /* webpackChunkName: "component---src-pages-himss-2024-js" */),
  "component---src-pages-hire-dedicated-developer-js": () => import("./../../../src/pages/hire-dedicated-developer.js" /* webpackChunkName: "component---src-pages-hire-dedicated-developer-js" */),
  "component---src-pages-hire-mobile-developers-js": () => import("./../../../src/pages/hire-mobile-developers.js" /* webpackChunkName: "component---src-pages-hire-mobile-developers-js" */),
  "component---src-pages-hire-nodejs-developer-js": () => import("./../../../src/pages/hire-nodejs-developer.js" /* webpackChunkName: "component---src-pages-hire-nodejs-developer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-consulting-js": () => import("./../../../src/pages/it-consulting.js" /* webpackChunkName: "component---src-pages-it-consulting-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-ebooks-js": () => import("./../../../src/pages/resources/ebooks.js" /* webpackChunkName: "component---src-pages-resources-ebooks-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-whitepapers-js": () => import("./../../../src/pages/resources/whitepapers.js" /* webpackChunkName: "component---src-pages-resources-whitepapers-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-talent-solution-build-your-team-js": () => import("./../../../src/pages/talent-solution/build-your-team.js" /* webpackChunkName: "component---src-pages-talent-solution-build-your-team-js" */),
  "component---src-pages-talent-solution-hire-developer-js": () => import("./../../../src/pages/talent-solution/hire-developer.js" /* webpackChunkName: "component---src-pages-talent-solution-hire-developer-js" */),
  "component---src-pages-talent-solution-js": () => import("./../../../src/pages/talent-solution.js" /* webpackChunkName: "component---src-pages-talent-solution-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-webinar-thank-you-js": () => import("./../../../src/pages/webinar-thank-you.js" /* webpackChunkName: "component---src-pages-webinar-thank-you-js" */),
  "component---src-pages-who-we-serve-enterprises-js": () => import("./../../../src/pages/who-we-serve/enterprises.js" /* webpackChunkName: "component---src-pages-who-we-serve-enterprises-js" */),
  "component---src-pages-who-we-serve-it-firms-js": () => import("./../../../src/pages/who-we-serve/it-firms.js" /* webpackChunkName: "component---src-pages-who-we-serve-it-firms-js" */),
  "component---src-pages-who-we-serve-scale-up-js": () => import("./../../../src/pages/who-we-serve/scale-up.js" /* webpackChunkName: "component---src-pages-who-we-serve-scale-up-js" */),
  "component---src-pages-who-we-serve-startup-js": () => import("./../../../src/pages/who-we-serve/startup.js" /* webpackChunkName: "component---src-pages-who-we-serve-startup-js" */),
  "component---src-templates-blog-author-list-js": () => import("./../../../src/templates/blog-author-list.js" /* webpackChunkName: "component---src-templates-blog-author-list-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-hire-js": () => import("./../../../src/templates/hire.js" /* webpackChunkName: "component---src-templates-hire-js" */),
  "component---src-templates-industrys-js": () => import("./../../../src/templates/industrys.js" /* webpackChunkName: "component---src-templates-industrys-js" */),
  "component---src-templates-jobdetail-js": () => import("./../../../src/templates/jobdetail.js" /* webpackChunkName: "component---src-templates-jobdetail-js" */),
  "component---src-templates-portfolio-details-js": () => import("./../../../src/templates/portfolio-details.js" /* webpackChunkName: "component---src-templates-portfolio-details-js" */),
  "component---src-templates-resource-pages-js": () => import("./../../../src/templates/resourcePages.js" /* webpackChunkName: "component---src-templates-resource-pages-js" */),
  "component---src-templates-services-f-2-js": () => import("./../../../src/templates/servicesF2.js" /* webpackChunkName: "component---src-templates-services-f-2-js" */),
  "component---src-templates-state-js": () => import("./../../../src/templates/state.js" /* webpackChunkName: "component---src-templates-state-js" */),
  "component---src-templates-webinar-js": () => import("./../../../src/templates/webinar.js" /* webpackChunkName: "component---src-templates-webinar-js" */),
  "component---src-templates-whatwedo-capabilities-services-js": () => import("./../../../src/templates/whatwedo&CapabilitiesServices.js" /* webpackChunkName: "component---src-templates-whatwedo-capabilities-services-js" */)
}

